import React from "react";

const SendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1401 2.95992L7.11012 5.95992C1.04012 7.98992 1.04012 11.2999 7.11012 13.3199L9.79012 14.2099L10.6801 16.8899C12.7001 22.9599 16.0201 22.9599 18.0401 16.8899L21.0501 7.86992C22.3901 3.81992 20.1901 1.60992 16.1401 2.95992ZM16.4601 8.33992L12.6601 12.1599C12.5101 12.3099 12.3201 12.3799 12.1301 12.3799C11.9401 12.3799 11.7501 12.3099 11.6001 12.1599C11.3101 11.8699 11.3101 11.3899 11.6001 11.0999L15.4001 7.27992C15.6901 6.98992 16.1701 6.98992 16.4601 7.27992C16.7501 7.56992 16.7501 8.04992 16.4601 8.33992Z"
        fill="#CA2D07"
      />
    </svg>
  );
};

export default SendIcon;
