import React from "react";
import { Container, Form } from "react-bootstrap";
import PriceMonthly from "./PriceMonthly";
import PriceYearly from "./PriceYearly";

const PricingTable = () => {
  const [priceSwith, setPriceSwith] = React.useState(false);
  const handleChangeMonth = () => {
    setPriceSwith(!priceSwith);
  };
  console.log(priceSwith);
  return (
    <div id="pricing">
      <div className="pricing-table-main-area-wrap">
        <Container>
          <div className="pricing-table-top-header-wrap">
            <div className="pricing-table-title">
              <p>Pricing Table</p>
              <h2>Simple, affordable pricing for all teams.</h2>
            </div>
            <div className="price-save-area-right">
              <p>Save upto 26%</p>
              <div className="monthly-yearly-area">
                <p>Monthly</p>
                <Form>
                  <Form.Check
                    onChange={handleChangeMonth}
                    type="switch"
                    id="custom-switch"
                    label=""
                  />
                </Form>
                <p>Yearly</p>
              </div>
            </div>
          </div>
          <div className="pricing-table-content-area-wrap">
            {priceSwith ? <PriceYearly /> : <PriceMonthly />}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PricingTable;
