import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Favicon from "react-favicon";
import { ReactTitle } from "react-meta-tags";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import LongArrow from "../../../assets/icons/LongArrow";
import favIcons from "../../Home/favicon.ico";
import { factoryData } from "../signatureStoryData/factoryLinkData";
const FactoryLink = () => {
  const sliderRef = useRef();
  const navigate = useLocation();
  const handleMouseWheel = (event) => {
    event.preventDefault();
    if (event.deltaY > 0) {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
  };
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    vertical: true,
    verticalSwiping: true,
    mouseWheel: true,
    easing: "linear",
    adaptiveHeight: true,
  };
  return (
    <div className="factory-link-main-area">
      {navigate?.pathname === "/centro/our-factory-link" && (
        <>
          <ReactTitle title="Centro" />
          <Favicon url={favIcons} />
        </>
      )}
      <Container>
        <div className="factory-link-content-area">
          <Row>
            <Col lg="6" className="my-auto">
              <div className="factory-link-left-content">
                <h2>Our factory link up</h2>
                <p>
                  With over 50+ ISO certified factory linkup, we ensure that our
                  clients <br /> get the best output of their desired products
                  adherence to safety <br /> and compliance check and every
                  step; <br /> competitive pricing included.
                </p>
              </div>
            </Col>
            <Col lg="6">
              <div
                className="factory-link-slider-card"
                onWheel={handleMouseWheel}
              >
                <div className="factory-link-slider-card-inner-box">
                  <Slider {...settings} ref={sliderRef}>
                    {factoryData?.map((data, idx) => (
                      <div className="factory-vertical-slider-card">
                        <div className="factory-link-bg-image">
                          <img src={data?.image} alt="" />
                        </div>
                        <div className="factory-link-content">
                          <div>
                            <h4>{data?.name}</h4>
                            <p>{data?.dec}</p>
                            <button className="factory-linkup-details-btn">
                              Read More <LongArrow />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default FactoryLink;
