import React from "react";

const LongArrow = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.704 1.284C10.6116 1.18711 10.5006 1.10977 10.3777 1.05658C10.2548 1.00339 10.1224 0.975433 9.98852 0.974359C9.8546 0.973286 9.72183 0.999122 9.59809 1.05034C9.47435 1.10155 9.36215 1.17709 9.26816 1.27249C9.17417 1.36789 9.1003 1.48119 9.05093 1.60568C9.00156 1.73017 8.9777 1.86331 8.98076 1.9972C8.98383 2.13108 9.01375 2.26299 9.06876 2.38509C9.12377 2.50719 9.20275 2.617 9.301 2.708L14.67 8H1C0.734784 8 0.48043 8.10536 0.292893 8.2929C0.105357 8.48043 0 8.73479 0 9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H14.665L9.3 15.285C9.12061 15.473 9.02145 15.7235 9.02353 15.9833C9.02561 16.2432 9.12877 16.492 9.31116 16.6772C9.49354 16.8623 9.74083 16.9691 10.0006 16.9751C10.2604 16.981 10.5123 16.8856 10.703 16.709L17.628 9.887C17.7459 9.77071 17.8396 9.63215 17.9035 9.47936C17.9674 9.32658 18.0003 9.16261 18.0003 8.997C18.0003 8.83139 17.9674 8.66743 17.9035 8.51464C17.8396 8.36186 17.7459 8.22329 17.628 8.107L10.703 1.284H10.704Z"
        fill="white"
      />
    </svg>
  );
};

export default LongArrow;
