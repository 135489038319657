import { createBrowserRouter } from "react-router-dom";
import Home from "../../../../Pages/Home/Home";
import SignatureStoryMenu from "../../../../Pages/signatureStory/SignatureStoryMenu";
import BrandWeWorked from "../../../../Pages/signatureStory/pages/BrandWeWorked";
import FactoryLink from "../../../../Pages/signatureStory/pages/FactoryLink";
import OurAugmented from "../../../../Pages/signatureStory/pages/OurAugmented";
import SubmitYourRequirments from "../../../../Pages/signatureStory/pages/SubmitYourRequirments";
import DDC from "../../../../src-test/DDC";
import MainHeader from "../MainHeader";
import SignatureStoryHeader from "../SignatureStoryHeader";

export const navbarRouter = createBrowserRouter([
  {
    path: "/",
    element: <DDC />,
  },
  {
    path: "/centro",
    element: <Home />,
  },
  //=================== signature story ==============
  {
    path: "/centro/signature-story",
    element: (
      <div>
        <SignatureStoryHeader signaturePage={"signature-story-page"} />
        <SignatureStoryMenu />
      </div>
    ),
  },
  {
    path: "/centro/our-factory-link",
    element: (
      <div>
        <SignatureStoryHeader />
        <FactoryLink />
      </div>
    ),
  },
  {
    path: "/centro/brand-work",
    element: (
      <div>
        <SignatureStoryHeader />
        <BrandWeWorked />
      </div>
    ),
  },
  {
    path: "/centro/our-augmented",
    element: (
      <div>
        <SignatureStoryHeader />
        <OurAugmented />
      </div>
    ),
  },
  {
    path: "/centro/submit-requirments",
    element: (
      <div>
        <SignatureStoryHeader />
        <SubmitYourRequirments />
      </div>
    ),
  },

  //===============================
  {
    path: "/our-product",
    element: (
      <div>
        <MainHeader />
      </div>
    ),
  },
  {
    path: "/our-process",
    element: (
      <div>
        <MainHeader />
      </div>
    ),
  },
  {
    path: "/our-people",
    element: (
      <div>
        <MainHeader />
      </div>
    ),
  },
  {
    path: "/work-with-us",
    element: (
      <div>
        <MainHeader />
      </div>
    ),
  },
  {
    path: "/connect",
    element: (
      <div>
        <MainHeader />
      </div>
    ),
  },
]);
