import React from "react";
import GalleryExport from "../../assets/icons/GalleryExport";
import SendIcon from "../../assets/icons/SendIcon";
import logo from "./header/img/logo (2) 1.png";
const ChatBoxShow = () => {
  return (
    <div className="chat-main-box">
      <div className="chat-header">
        <h5>Centro support</h5>
      </div>
      <div className="chat-content-body">
        <div className="centro-support-message-body">
          <div className="centro-admin-message">
            <div className="centro-logo">
              <img src={logo} alt="" />
            </div>
            <div className="centro-message">
              <p>How can we help you ?</p>
            </div>
          </div>
          {/* <div className="centro-client-message">
            <div className="client-logo">
              <h2>C</h2>
            </div>
            <div className="centro-message">
              <p>Yes?</p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="chat-content-input">
        <div className="type-message">
          <input type="text" placeholder="Compose Your Message...." />
        </div>
        <div className="file-message-upload">
          <label htmlFor="upload-chat-file">
            <GalleryExport />
          </label>
          <input type="file" id="upload-chat-file" />
        </div>
        <div className="send-message">
          <SendIcon />
        </div>
      </div>
    </div>
  );
};

export default ChatBoxShow;
