import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LongArrow from "../../assets/icons/LongArrow";
import image from "./img/signature-story/home-signature-story.png";
const SignatureStory = () => {
  return (
    <div className="signature-story-main-area-wrap" id="signature-story">
      <Container>
        <div className="signature-story-content-area-wrap">
          <Row>
            <Col lg="6">
              <div className="signature-story-content-left-content">
                <div>
                  <ScrollAnimation animateIn="animate__fadeInLeft" duration={1}>
                    <h6>SIGNATURE STORY</h6>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInRight"
                    duration={1}
                  >
                    <h3>
                      How can we give you <br /> THE EDGE
                    </h3>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInRight"
                    duration={1}
                  >
                    <p>
                      We aim to be the preferred supplier in Asia for all of our
                      clients globally.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
                    <Link
                      to="/centro/signature-story"
                      className="common-centro-btn "
                    >
                      View More <LongArrow />
                    </Link>
                  </ScrollAnimation>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <ScrollAnimation animateIn="animate__fadeInDown" duration={1}>
                <div className="signature-story-content-right-image">
                  <img src={image} alt="" />
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SignatureStory;
