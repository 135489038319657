import image1 from "../pages/images/factory/1.png";
import image2 from "../pages/images/factory/2.png";
import image3 from "../pages/images/factory/3.png";
export const factoryData = [
  {
    name: "POSH GARMENTS",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image1,
    buttonLink: "/centro/our-factory-link",
  },
  {
    name: "Ha-meem Group.",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image2,
    buttonLink: "/centro/our-factory-link",
  },
  {
    name: "Epyllion Group.",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image3,
    buttonLink: "/centro/our-factory-link",
  },
  // {
  //   name: "POSH GARMENTS 4",
  //   dec: "The marker planner uses full-size patterns and arrange",
  //   image: image1,
  //   buttonLink: "/centro/our-factory-link",
  // },
];
