import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import Distributors from "./WorkProcess/Distributors";
import Supervisor from "./WorkProcess/Supervisor";
import SupportOfficer from "./WorkProcess/SupportOfficer";

const WorksArea = () => {
  return (
    <div id="works">
      <div className="work-area-main-wrap">
        <Container>
          <div className="work-area-title">
            <p>How It Works</p>

            <h2>Getting started is easy, and lovely.</h2>
          </div>
          <div className="work-area-content-wrap">
            <Tabs defaultActiveKey="sales" id="work" className="mb-3">
              <Tab eventKey="sales" title="Sales Unit">
                <Distributors />
              </Tab>
              <Tab eventKey="bpunit" title="SupeBP Unit">
                <Supervisor />
              </Tab>
              <Tab eventKey="SupportOfficer" title="Merchandising Unit">
                <SupportOfficer />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default WorksArea;
