import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import image from "./img/centroFeatureImage/product 1.png";
import image2 from "./img/centroFeatureImage/shirts 1.png";
import image3 from "./img/centroFeatureImage/teamwork 1.png";
const CentroFeature = () => {
  return (
    <div className="centro-feature-main-area">
      <Container>
        <div className="centro-feature-content-area-wrap">
          <Row>
            <Col lg={4}>
              <div className="single-centro-feature-card">
                <ScrollAnimation animateIn="animate__fadeInTop">
                  <img src={image} alt="" />
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInLeft">
                  <h4>INDUSTRY LEADING PRODUCT</h4>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <p>
                    Carefully managing our supply chain to minimize wastage and
                    maximizing productivity.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
            <Col lg={4}>
              <div className="single-centro-feature-card">
                <ScrollAnimation animateIn="animate__fadeInTop">
                  <img src={image2} alt="" />
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInLeft">
                  <h4>PRODUCTION FLEXIBILITY AND MANAGEMENT</h4>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <p>
                    Carefully managing our supply chain to minimize wastage and
                    maximizing productivity.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
            <Col lg={4}>
              <div className="single-centro-feature-card">
                <ScrollAnimation animateIn="animate__fadeInTop">
                  <img src={image3} alt="" />
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInLeft">
                  <h4>OUR CULTURE</h4>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <p>
                    Carefully managing our supply chain to minimize wastage and
                    maximizing productivity.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CentroFeature;
