import { configureStore } from "@reduxjs/toolkit";
import getHeroSlice from "../hero/heroSlice";
import getCategorySlice from "../product/categorySlice";
import getProductSlice from "../product/productSlice";

export const store = configureStore({
  reducer: {
    product: getProductSlice,
    hero: getHeroSlice,
    category: getCategorySlice,
  },
});
