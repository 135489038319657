import React from "react";
import { Container } from "react-bootstrap";
import Favicon from "react-favicon";
import { ReactTitle } from "react-meta-tags";
import { useLocation } from "react-router-dom";
import favIcons from "../../Home/favicon.ico";
import bgImage from "./images/our-augmented.png";
const OurAugmented = () => {
  const navigate = useLocation();
  return (
    <div>
      {navigate?.pathname === "/centro/signature-story" && (
        <>
          <ReactTitle title="Centro" />
          <Favicon url={favIcons} />
        </>
      )}
      <Container>
        <div
          className="our-augmented-player-wrap"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <>
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="45" cy="45" r="45" fill="white" />
              <path
                d="M58.9277 44.134C59.5944 44.5189 59.5944 45.4811 58.9277 45.866L38.7849 57.4955C38.1182 57.8804 37.2849 57.3993 37.2849 56.6295V33.3705C37.2849 32.6007 38.1182 32.1196 38.7849 32.5045L58.9277 44.134Z"
                fill="#F43000"
              />
            </svg>
          </>
        </div>
      </Container>
    </div>
  );
};

export default OurAugmented;
