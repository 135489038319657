import React from "react";
import { Col, Row } from "react-bootstrap";

const PriceYearly = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div className="pricing-table-single-item">
            <div className="pricing-table-single-item-header">
              <h3>Basic</h3>
              <p>Free 7-day trial. No credit card required. Cancel anytime.</p>
              <h2>
                BDT 3500 <span>/Year /User</span>
              </h2>
              <hr />
            </div>
            <div className="pricing-table-single-item-body">
              <div className="pricing-table-features">
                <ul>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>Upto 30 Users</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>1 Project</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>1 Website</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="pricing-table-single-item-footer">
              <a
                href="https://maacsolutions.com/course-demo-request?coursename=DDC"
                target="_blank"
                rel="noreferrer"
              >
                Request for Demo
              </a>
            </div>
          </div>
        </Col>{" "}
        <Col lg={4}>
          <div className="pricing-table-single-item recommended">
            <div className="recommended-package">
              <p>Recommended</p>
            </div>
            <div className="pricing-table-single-item-header">
              <h3>Intermediate</h3>
              <p>Free 7-day trial. No credit card required. Cancel anytime.</p>
              <h2>
                BDT 7000 <span>/Year /User</span>
              </h2>
              <hr />
            </div>
            <div className="pricing-table-single-item-body">
              <div className="pricing-table-features">
                <ul>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>31 - 500 Users</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>5 Projects</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>1 Website</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="pricing-table-single-item-footer">
              <a
                href="https://maacsolutions.com/course-demo-request?coursename=DDC"
                target="_blank"
                rel="noreferrer"
              >
                Request for Demo
              </a>
            </div>
          </div>
        </Col>{" "}
        <Col lg={4}>
          <div className="pricing-table-single-item">
            <div className="pricing-table-single-item-header">
              <h3>Advanced</h3>
              <p>Free 7-day trial. No credit card required. Cancel anytime.</p>
              <h2>
                BDT 11000 <span>/Year /User</span>
              </h2>
              <hr />
            </div>
            <div className="pricing-table-single-item-body">
              <div className="pricing-table-features">
                <ul>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>Unlimited Users</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>Unlimited Projects</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span>1 Website</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="pricing-table-single-item-footer">
              <a
                href="https://maacsolutions.com/course-demo-request?coursename=DDC"
                target="_blank"
                rel="noreferrer"
              >
                Request for Demo
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PriceYearly;
