import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-scroll";
import "./header.scss";
import logo from "./img/logo (2) 1.png";
const MainHeader = () => {
  return (
    <div className="header-main-area">
      <Container>
        <div className="header-top-area-logo">
          <img src={logo} alt="" />
        </div>
        <div className="header-bottom-area-navbar">
          <ul>
            {/* {navbarLink.map((nav, idx) => (
              <li key={idx}>
                <Link activeClass="active" to={nav?.link}>
                  {nav?.title}
                </Link>
              </li>
            ))} */}
            <li>
              <Link
                activeClass="active"
                to="c-hero"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                delay={100}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="our-product"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                delay={100}
              >
                Our product
              </Link>{" "}
            </li>
            <li>
              <Link
                activeClass="active"
                to="our-process"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                delay={100}
              >
                Our process
              </Link>{" "}
            </li>
            <li>
              <Link
                activeClass="active"
                to="signature-story"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                delay={100}
              >
                Signature story
              </Link>{" "}
            </li>
            <li>
              <Link
                activeClass="active"
                to="our-people"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                delay={100}
              >
                Our people
              </Link>{" "}
            </li>
            <li>
              <Link
                activeClass="active"
                to="work-with-us"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                delay={100}
              >
                Work with us
              </Link>{" "}
            </li>

            <li>
              <Link
                activeClass="active"
                to="connect"
                spy={true}
                smooth={true}
                offset={-170}
                duration={500}
                delay={100}
              >
                Let’s Connect
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default MainHeader;
