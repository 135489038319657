import React from "react";
import { Container } from "react-bootstrap";
import playstoreIcon from "./icon/bxl_play-store.svg";
import imageBanner from "./icon/salesXImage.png";
const ProductReview = () => {
  return (
    <div id="demo_app">
      <div className="prduct-review-area-main-wrap">
        <Container>
          <div className="product-review-inner-area">
            <div className="product-review-title">
              <p>App Features</p>
              <h2>Find our app on</h2>
            </div>
            <div className="product-review-content">
              <div className="review-content-single-left image-banner-playstore">
                <img src={imageBanner} alt="" />
              </div>{" "}
              {/* <div className="review-content-devider"></div> */}
              <div className="review-content-single-right">
                <div className="playstore-icon">
                  <img src={playstoreIcon} alt="" />
                  <h3>Play Store Feedback</h3>
                  <div className="feedback-rating">
                    <span>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                    </span>
                    <span>(400 Rating)</span>
                  </div>
                </div>
                <div className="review-feedback-information-wrap">
                  <div className="review-feedback-information">
                    <div className="r-title">
                      <b>Category:</b>
                    </div>
                    <div className="r-content">
                      <p>Business</p>
                    </div>
                  </div>{" "}
                  <div className="review-feedback-information">
                    <div className="r-title">
                      <b>Updated:</b>
                    </div>
                    <div className="r-content">
                      <p>Dec 20, 2022</p>
                    </div>
                  </div>{" "}
                  <div className="review-feedback-information">
                    <div className="r-title">
                      <b>Version:</b>
                    </div>
                    <div className="r-content">
                      <p>2.0.8</p>
                    </div>
                  </div>{" "}
                  <div className="review-feedback-information">
                    <div className="r-title">
                      <b>Size:</b>
                    </div>
                    <div className="r-content">
                      <p>54MB</p>
                    </div>
                  </div>{" "}
                  <div className="review-feedback-information">
                    <div className="r-title">
                      <b>Language:</b>
                    </div>
                    <div className="r-content">
                      <p>Eng, Ban</p>
                    </div>
                  </div>{" "}
                  <div className="review-feedback-information">
                    <div className="r-title">
                      <b>Seller:</b>
                    </div>
                    <div className="r-content">
                      <p>DDC</p>
                    </div>
                  </div>
                  <div className="review-app-download-link">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.maacddc.ddc"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Download Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ProductReview;
