import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TruncateLines } from "react-truncate-lines";
const postData = [
  {
    id: "3",
    icon: "1655195842234-3.png",
    title: "Transcendence: Blueprint for next level loyalty",
    content: ` Since most exciting consumer journey is written and subsequently brands are being built in retails, marketers always look for different answers around the retail globe. And over the years, retails have been the cornerstone of consumer decision journey as well as ‘loyalty’ loop for both consumer and for retailers.`,
    author: "Nazmus Shakib",

    link: "https://maacsolutions.com/insight-post-3",
    date: "Jan 3 2022",
  },
  {
    id: "4",
    icon: "1655196438961-4.png",
    title: "Startup cell and growth",
    content: ` Target to acquire 800K outlets, in 18 months period is very challenging task indeed. This is unprecendented in the retail market where people are acquired through microservice, and purchase regularly through apps.`,
    author: "Nazmus Shakib",
    link: "https://maacsolutions.com/insight-post-4",
    date: "Jan 4 2022",
  },
  {
    id: "10",
    icon: "1655197177892-10.png",
    title: "MAAC LMS: Enabling & Empowering digital learning",
    content: `Businesses need to manage their resources wisely if they are going to succeed. In this fast-paced business world, learning and developing skills is a must to maintain growth and stay ahead of the curve. One of the best-proven methods in the last 10 years globally has been integrating learning with technology so that the process is agile and adaptive for all users. `,
    author: "Nafisuzzaman",
    link: "https://maacsolutions.com/insight-post-10",
    date: "Jan 10 2022",
  },
];
const imageUrl = "https://staging-ddc.sgp1.digitaloceanspaces.com/";
const Blogs = () => {
  return (
    <div id="Testimonials">
      <div className="blog-main-area-wrap">
        <Container>
          <div className="blog-inner-area-wrap">
            <div className="blog-area-title">
              <p>New Resources</p>
              <h2>DDC stories</h2>
            </div>
            <div className="blog-area-content">
              <Row>
                {postData.map((blog, idx) => (
                  <Col key={idx} lg={4} sm={12} className="mb-3">
                    <a
                      href={blog.link}
                      target="_blank"
                      rel="noreferrer"
                      className="blog-single-item"
                    >
                      <div className="blog-top-image">
                        <img src={`${imageUrl}${blog.icon}`} alt="icon" />
                      </div>
                      <div className="blog-bottom-content">
                        <a href={blog.link} target="_blank" rel="noreferrer">
                          <h4>
                            <TruncateLines
                              lines={1}
                              ellipsis={<span>...</span>}
                            >
                              {blog.title}
                            </TruncateLines>
                          </h4>
                        </a>
                        <p>
                          <TruncateLines lines={2} ellipsis={<span>...</span>}>
                            {blog.content}
                          </TruncateLines>
                        </p>
                      </div>
                      <hr />
                      <div className="blog-content-footer">
                        <div className="blog-content-footer-left">
                          <i className="bx bx-user-circle"></i>
                          <span>{blog.author}</span>
                        </div>
                        <div className="blog-content-footer-right">
                          <i className="bx bx-time-five"></i>
                          <span>
                            <time dateTime={blog.date}>{blog.date}</time>
                          </span>
                        </div>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Blogs;
