import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  products: [],
  error: "",
};

export const onFetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async () => {
    const products = await fetch(
      "https://maacsolutionsbackend-api.salesx-staging.xyz/api/v1/product/data"
    );
    return products.json();
  }
);

const getProductSlice = createSlice({
  name: "productReader",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(onFetchProduct.pending, (state, action) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(onFetchProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload;
        state.error = "";
      })
      .addCase(onFetchProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.products = [];
        state.error = action.error.message;
      });
  },
});

export default getProductSlice.reducer;
