import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./header.scss";
import logo from "./img/logo (2) 1.png";
const SignatureStoryHeader = ({ signaturePage }) => {
  console.log(signaturePage);
  return (
    <>
      <Container>
        <div className={`signature-header-top-area-logo ${signaturePage}`}>
          <Link to="/centro/signature-story">
            <img src={logo} alt="" />
          </Link>
        </div>
      </Container>
    </>
  );
};

export default SignatureStoryHeader;
