import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { onFetchHero } from "../../store/hero/heroSlice";
import heroImage from "./img/hero-right.png";
const Hero = () => {
  const { isLoading, hero } = useSelector((state) => state?.hero);
  // console.log(datas);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onFetchHero("Hero"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const heoData = hero?.datas?.find((el) => el?.type === "Hero");
  // console.log(heoData, hero?.datas);

  return (
    <div className="hero-main-area" id="c-hero">
      <Container>
        <div className="hero-inner-area-wrap">
          <div className="hero-feature-area-right">
            {isLoading ? (
              <img src={heroImage} alt="" />
            ) : (
              <img src={heoData?.image} alt="" />
            )}
          </div>
          <h2>
            <Zoom left cascade>
              CE
              <span>NTRO</span>
            </Zoom>
          </h2>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
