import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import LongArrow from "../../assets/icons/LongArrow";
import image1 from "./img/workingProcessImage/image 1.png";
import image2 from "./img/workingProcessImage/image 2.png";
import image3 from "./img/workingProcessImage/image 3.png";
const WorkingProcessNew = () => {
  return (
    <div className="working-process-new-main-wrap" id="our-process">
      <Container>
        <div className="working-process-content-area-wrap">
          <div className="working-process-new-title">
            <h2>Working Process</h2>
          </div>
          <div className="working-process-new-items">
            <Row>
              <Col lg="4">
                <ScrollAnimation animateIn="animate__fadeInDown" duration={1}>
                  {" "}
                  <div className="working-process-single-card-new">
                    <div className="working-p-content-part-front">
                      <img src={image1} alt="" className="img-fluid" />
                      <div className="w-p-c-part-front-bottom">
                        <h1>01</h1>
                        <h4>
                          Design & <br /> Development
                        </h4>
                      </div>
                    </div>
                    <div className="working-p-content-part-hover">
                      <div>
                        <h3>Design & Development</h3>
                        <p>
                          An innovative team ensuring the right mix of
                          directional pieces and fashion essentials.
                        </p>
                        <button className="common-centro-btn">
                          View More <LongArrow />
                        </button>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>{" "}
              <Col lg="4">
                {" "}
                <ScrollAnimation animateIn="animate__fadeInDown" duration={1.2}>
                  {" "}
                  <div className="working-process-single-card-new">
                    <div className="working-p-content-part-front">
                      <img src={image2} alt="" className="img-fluid" />
                      <div className="w-p-c-part-front-bottom">
                        <h1>02</h1>
                        <h4>Merchandising</h4>
                      </div>
                    </div>
                    <div className="working-p-content-part-hover">
                      <div>
                        <h3>Merchandising</h3>
                        <p>
                          Our team manages the lifecycle of each garment,
                          directing our supply base deliver product on time with
                          standard.
                        </p>
                        <button className="common-centro-btn">
                          View More <LongArrow />
                        </button>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>{" "}
              <Col lg="4">
                {" "}
                <ScrollAnimation animateIn="animate__fadeInDown" duration={1.4}>
                  <div className="working-process-single-card-new">
                    <div className="working-p-content-part-front">
                      <img src={image3} alt="" className="img-fluid" />
                      <div className="w-p-c-part-front-bottom">
                        <h1>03</h1>
                        <h4>
                          Quality Checking, <br /> Assurance & Testing
                        </h4>
                      </div>
                    </div>
                    <div className="working-p-content-part-hover">
                      <div>
                        <h3>
                          Quality Checking, <br /> Assurance & Testing
                        </h3>
                        <p>
                          Transparency of procedure is one of our core
                          principles and we welcome the opportunity to review
                        </p>
                        <button className="common-centro-btn">
                          View More <LongArrow />
                        </button>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WorkingProcessNew;
