import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "react-bootstrap";
import LongArrow from "../../assets/icons/LongArrow";
import peopleImage from "./img/meet-our-people-image.png";
const MeetOurPeople = () => {
  return (
    <div className="meet-our-people-main-area" id="our-people">
      <Container>
        <div
          className="meet-our-people-content-area"
          style={{ backgroundImage: `url(${peopleImage})` }}
        >
          <div className="meet-our-people-content">
            <ScrollAnimation animateIn="animate__fadeInLeft">
              <h3>Meet Our People</h3>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInRight">
              {" "}
              <p>
                Get to know the people behind our company and discover <br />{" "}
                their passion for what they do.
              </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInDown">
              {" "}
              <button className="common-centro-btn">
                Learn More <LongArrow />
              </button>
            </ScrollAnimation>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MeetOurPeople;
