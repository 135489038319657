import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Modal } from "react-bootstrap";
import LongArrow from "../../assets/icons/LongArrow";
import CallToActionModalData from "./CallToActionModalData";

const CallToAction = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="call-to-action-main-area" id="connect">
      <>
        <Modal
          show={show}
          onHide={handleClose}
          animation={true}
          size={"lg"}
          centered
        >
          <CallToActionModalData handleClose={handleClose} />
        </Modal>
      </>
      <Container>
        <div className="call-to-action-content-wrap">
          <div className="call-to-action-content-left">
            <ScrollAnimation animateIn="animate__fadeInRight">
              {" "}
              <h2>
                LET'S MAKE YOUR <br />
                FASHION BRAND SHINE
              </h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInRight">
              <p>
                Unleash the full potential of your fashion brand <br /> with our
                expert team.
              </p>
            </ScrollAnimation>
          </div>
          <div className="call-to-action-button-right">
            <ScrollAnimation animateIn="animate__fadeInLeft">
              <button className="common-centro-btn" onClick={handleShow}>
                Connect <LongArrow />
              </button>
            </ScrollAnimation>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CallToAction;
