import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LongArrow from "../../assets/icons/LongArrow";
import { onFetchHero } from "../../store/hero/heroSlice";
import aboutImage from "./img/about-iamge-left.png";
const AboutUs = () => {
  const { isLoading, about } = useSelector((state) => state?.hero);
  // console.log(data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onFetchHero("About"));
  }, [dispatch]);

  const aboutFilterData = about?.datas?.filter(
    (data) => data?.type === "About"
  );

  const heoData2 = aboutFilterData?.[0];

  return (
    <div className="about-main-area-wrap">
      <Container>
        <Row>
          <Col lg="6">
            <div className="about-single-card">
              {isLoading ? (
                <img src={aboutImage} alt="" />
              ) : (
                <img src={heoData2?.image} alt="" />
              )}
              {/* <img src={aboutImage} alt="" /> */}
            </div>
          </Col>
          <Col lg="6">
            <div className="about-single-card-right">
              <div className="about-single-cart-inner-content">
                <ScrollAnimation animateIn="animate__fadeInDown" duration={1}>
                  <h6>{heoData2?.type}</h6>
                </ScrollAnimation>{" "}
                <ScrollAnimation animateIn="animate__fadeInDown" duration={1}>
                  <h2>{heoData2?.title}</h2>
                </ScrollAnimation>{" "}
                <ScrollAnimation animateIn="animate__fadeInRight" duration={1}>
                  <p>{heoData2?.details}</p>
                </ScrollAnimation>{" "}
                <ScrollAnimation animateIn="animate__fadeInLeft" duration={1}>
                  <button className="common-centro-btn">
                    Learn More <LongArrow />
                  </button>
                </ScrollAnimation>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
