import productImage1 from "./images/products/1.png";
import productImage2 from "./images/products/2.png";
import productImage3 from "./images/products/3.png";
import productImage4 from "./images/products/4.png";
import productImage5 from "./images/products/5.png";
import productImage6 from "./images/products/6.png";
//==================
import designImage1 from "./images/design/1.png";
import designImage2 from "./images/design/2.png";
import designImage3 from "./images/design/3.png";
import designImage4 from "./images/design/4.png";
import designImage5 from "./images/design/5.png";
import designImage6 from "./images/design/6.png";
//==================
import fabricsImage1 from "./images/fabrics/1.png";
import fabricsImage2 from "./images/fabrics/2.png";
import fabricsImage3 from "./images/fabrics/3.png";
import fabricsImage4 from "./images/fabrics/4.png";
import fabricsImage5 from "./images/fabrics/5.png";
import fabricsImage6 from "./images/fabrics/6.png";
//==================
import printImage1 from "./images/print/1.png";
import printImage2 from "./images/print/2.png";
import printImage3 from "./images/print/3.png";
import printImage4 from "./images/print/4.png";
import printImage5 from "./images/print/5.png";
import printImage6 from "./images/print/6.png";
//==================
import accessoriesImage1 from "./images/accessories/1.png";
import accessoriesImage2 from "./images/accessories/2.png";
import accessoriesImage3 from "./images/accessories/3.png";
import accessoriesImage4 from "./images/accessories/4.png";
import accessoriesImage5 from "./images/accessories/5.png";
import accessoriesImage6 from "./images/accessories/6.png";
export const selectProducts = [
  {
    type: ["product"],
    name: "Men’s t-shirt",
    image: productImage1,
  },
  {
    type: ["product"],
    name: "Kid’s dress",
    image: productImage2,
  },
  {
    type: ["product"],
    name: "Women’s t-shirt",
    image: productImage3,
  },
  {
    type: ["product"],
    name: "Men’s Huddie",
    image: productImage4,
  },
  {
    type: ["product"],
    name: "Men’s blazer",
    image: productImage5,
  },
  {
    type: ["product"],
    name: "Men’s t-shirt",
    image: productImage6,
  },
  //============================ design data ============================
  {
    type: ["design"],
    name: "Men’s t-shirt",
    image: designImage1,
  },
  {
    type: ["design"],
    name: "Men’s t-shirt",
    image: designImage2,
  },
  {
    type: ["design"],
    name: "Men’s t-shirt",
    image: designImage3,
  },
  {
    type: ["design"],
    name: "Men’s t-shirt",
    image: designImage4,
  },
  {
    type: ["design"],
    name: "Men’s t-shirt",
    image: designImage5,
  },
  {
    type: ["design"],
    name: "Men’s t-shirt",
    image: designImage6,
  },
  //============================ Fabrics data ============================
  {
    type: ["fabrics"],
    name: "Men’s t-shirt",
    image: fabricsImage1,
  },
  {
    type: ["fabrics"],
    name: "Men’s t-shirt",
    image: fabricsImage2,
  },
  {
    type: ["fabrics"],
    name: "Men’s t-shirt",
    image: fabricsImage3,
  },
  {
    type: ["fabrics"],
    name: "Men’s t-shirt",
    image: fabricsImage4,
  },
  {
    type: ["fabrics"],
    name: "Men’s t-shirt",
    image: fabricsImage5,
  },

  {
    type: ["fabrics"],
    name: "Men’s t-shirt",
    image: fabricsImage6,
  },
  //============================ Print data ============================
  {
    type: ["print"],
    name: "Flock Print",
    image: printImage1,
  },
  {
    type: ["print"],
    name: "Foil Print",
    image: printImage2,
  },
  {
    type: ["print"],
    name: "Glitter Print",
    image: printImage3,
  },
  {
    type: ["print"],
    name: "Heat Transfer Print",
    image: printImage4,
  },
  {
    type: ["print"],
    name: "Flock Print",
    image: printImage5,
  },
  {
    type: ["print"],
    name: "Foil Print",
    image: printImage6,
  },
  //=============== accessories =============
  {
    type: ["accessories"],
    name: "Shirt Collar",
    image: accessoriesImage1,
  },
  {
    type: ["accessories"],
    name: "Shirt Bottom",
    image: accessoriesImage2,
  },
  {
    type: ["accessories"],
    name: "Men’s t-shirt",
    image: accessoriesImage3,
  },
  {
    type: ["accessories"],
    name: "Men’s t-shirt",
    image: accessoriesImage4,
  },
  {
    type: ["accessories"],
    name: "Men’s t-shirt",
    image: accessoriesImage5,
  },
  {
    type: ["accessories"],
    name: "Men’s t-shirt",
    image: accessoriesImage6,
  },
];

export const requirementList = [
  {
    id: 1,
    name: "Select Products",
    value: "product",
  },
  { id: 2, name: "Select Design", value: "design" },
  { id: 3, name: "Select Fabrics", value: "fabrics" },
  { id: 4, name: "Select Print", value: "print" },
  { id: 5, name: "Select Accessories", value: "accessories" },
  { id: 6, name: "Select Delivery Location", value: "location" },
];
