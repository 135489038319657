import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Favicon from "react-favicon";
import { ReactTitle } from "react-meta-tags";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import favIcons from "../../Home/favicon.ico";
import { brandData } from "../signatureStoryData/brand/brandData";
const BrandWeWorked = () => {
  const navigate = useLocation();
  const sliderRef = useRef();
  const handleMouseWheel = (event) => {
    event.preventDefault();
    if (event.deltaY > 0) {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    vertical: true,
    verticalSwiping: true,
    mouseWheel: true,
    easing: "linear",
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <div className="factory-link-main-area brand-work-page">
      {navigate?.pathname === "/centro/signature-story" && (
        <>
          <ReactTitle title="Centro" />
          <Favicon url={favIcons} />
        </>
      )}
      <Container>
        <div className="factory-link-content-area">
          <Row>
            <Col lg="6" className="my-auto">
              <div className="factory-link-left-content">
                <h2>
                  Brand we worked <br /> with
                </h2>
                <p>
                  Over the years, working with TOP brands, we ensured our
                  capabilities <br /> have evolved so that we can serve you
                  better
                </p>
              </div>
            </Col>
            <Col lg="6">
              <div
                className="factory-link-slider-card"
                onWheel={handleMouseWheel}
              >
                <div className="factory-link-slider-card-inner-box">
                  <Slider {...settings} ref={sliderRef}>
                    {brandData?.map((data, idx) => (
                      <div className="factory-vertical-slider-card brand-work">
                        <div className="factory-link-content">
                          <div>
                            <img src={data?.image} alt="" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default BrandWeWorked;
