import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import aboutBg from "./about-bg.png";
import aboutImg from "./about.png";
import profileImg from "./profileImage.png";
const AboutUs = () => {
  return (
    <div id="about_us">
      <div
        className="about-main-area-wrap"
        style={{ backgroundImage: "url(" + aboutBg + ")" }}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-left-area">
                <img src={aboutImg} alt="" />
              </div>
            </Col>
            <Col lg={6} className="my-auto">
              <div className="about-right-area">
                <div className="about-title">
                  <p>About Us</p>
                </div>
                <div className="about-content">
                  <h4>
                    A dedicated solution for startups <br /> a enterprise, and
                    agencies
                  </h4>

                  <hr />
                  <div className="about-profile-info-bottom">
                    <div className="about-profile-info-bottom-left-icon">
                      <img src={profileImg} alt="" />
                    </div>
                    <div className="about-profile-info-bottom-left-content">
                      <p>
                        "DDC is for teams that care about their product growth."
                      </p>
                      <h5>CEO, DDC</h5>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="about-area-bottom-info">
            <Row>
              <Col lg={3} md={3} sm={6}>
                <div className="about-bottom-single-item box-one-item">
                  <div className="about-bottom-single-item-inner-box">
                    <i>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 33.9001H12.42C5.68 33.9001 4 32.2201 4 25.4801V13.4801C4 6.74006 5.68 5.06006 12.42 5.06006H33.48C40.22 5.06006 41.9 6.74006 41.9 13.4801"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 42.9399V33.8999"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 25.8999H20"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.4805 42.9399H20.0005"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M43.9996 25.5999V37.0199C43.9996 41.7599 42.8196 42.9399 38.0796 42.9399H30.9796C26.2396 42.9399 25.0596 41.7599 25.0596 37.0199V25.5999C25.0596 20.8599 26.2396 19.6799 30.9796 19.6799H38.0796C42.8196 19.6799 43.9996 20.8599 43.9996 25.5999Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M34.489 36.5H34.507"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                    <h3>8.33%</h3>
                    <p>Sales Increse/day/SR</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={3} sm={6}>
                <div className="about-bottom-single-item box-two-item">
                  <div className="about-bottom-single-item-inner-box">
                    <i>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M36.0002 14.32C35.8802 14.3 35.7402 14.3 35.6202 14.32C32.8602 14.22 30.6602 11.96 30.6602 9.16C30.6602 6.3 32.9602 4 35.8202 4C38.6802 4 40.9801 6.32 40.9801 9.16C40.9601 11.96 38.7602 14.22 36.0002 14.32Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M33.9399 28.8801C36.6799 29.3401 39.6999 28.8601 41.8199 27.4401C44.6399 25.5601 44.6399 22.4801 41.8199 20.6001C39.6799 19.1801 36.6199 18.7001 33.8799 19.1801"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.94 14.32C12.06 14.3 12.2 14.3 12.32 14.32C15.08 14.22 17.28 11.96 17.28 9.16C17.28 6.3 14.98 4 12.12 4C9.25996 4 6.95996 6.32 6.95996 9.16C6.97996 11.96 9.17996 14.22 11.94 14.32Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.9999 28.8801C11.2599 29.3401 8.23988 28.8601 6.11988 27.4401C3.29988 25.5601 3.29988 22.4801 6.11988 20.6001C8.25988 19.1801 11.3199 18.7001 14.0599 19.1801"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M24.0002 29.2599C23.8802 29.2399 23.7402 29.2399 23.6202 29.2599C20.8602 29.1599 18.6602 26.8999 18.6602 24.0999C18.6602 21.2399 20.9602 18.9399 23.8202 18.9399C26.6802 18.9399 28.9802 21.2599 28.9802 24.0999C28.9602 26.8999 26.7602 29.1799 24.0002 29.2599Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.1804 35.56C15.3604 37.44 15.3604 40.52 18.1804 42.4C21.3804 44.54 26.6204 44.54 29.8204 42.4C32.6404 40.52 32.6404 37.44 29.8204 35.56C26.6404 33.44 21.3804 33.44 18.1804 35.56Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                    <h3>100M</h3>
                    <p>BDT amount of additional sales</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={3} sm={6}>
                <div className="about-bottom-single-item box-three-item">
                  <div className="about-bottom-single-item-inner-box">
                    <i>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24 24C29.5228 24 34 19.5228 34 14C34 8.47715 29.5228 4 24 4C18.4772 4 14 8.47715 14 14C14 19.5228 18.4772 24 24 24Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M41.1803 44C41.1803 36.26 33.4803 30 24.0003 30C14.5203 30 6.82031 36.26 6.82031 44"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                    <h3>10%+</h3>
                    <p>Reduction in sales cycle</p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={3} sm={6}>
                <div className="about-bottom-single-item box-four-item">
                  <div className="about-bottom-single-item-inner-box">
                    <i>
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.2998 33V37.2"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.2998 44H34.2998V42C34.2998 39.8 32.4998 38 30.2998 38H18.2998C16.0998 38 14.2998 39.8 14.2998 42V44V44Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M12.2998 44H36.2998"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M24 32C16.26 32 10 25.74 10 18V12C10 7.58 13.58 4 18 4H30C34.42 4 38 7.58 38 12V18C38 25.74 31.74 32 24 32Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.9401 23.3C9.44008 22.82 8.12008 21.94 7.08008 20.9C5.28008 18.9 4.08008 16.5 4.08008 13.7C4.08008 10.9 6.28008 8.69995 9.08008 8.69995H10.3801C9.98008 9.61995 9.78008 10.64 9.78008 11.7V17.7C9.78008 19.7 10.2001 21.58 10.9401 23.3Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M37.0596 23.3C38.5596 22.82 39.8796 21.94 40.9196 20.9C42.7196 18.9 43.9196 16.5 43.9196 13.7C43.9196 10.9 41.7196 8.69995 38.9196 8.69995H37.6196C38.0196 9.61995 38.2196 10.64 38.2196 11.7V17.7C38.2196 19.7 37.7996 21.58 37.0596 23.3Z"
                          stroke="#1AB389"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                    <h3>10%+</h3>
                    <p>Performance Increment/QTR/SR</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AboutUs;
