import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import "./assets/scss/styles.scss";
import FieldX from "./Pages/FieldX/FieldX";
const DDC = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<FieldX />} />
      </Routes>
    </div>
  );
};

export default DDC;
