import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  hero: [],
  about: [],
  error: "",
};

export const onFetchHero = createAsyncThunk(
  "phero/fetchHero",
  async (id = "Hero") => {
    const data = await fetch(
      `https://maacsolutionsbackend-api.salesx-staging.xyz/api/v1/hero-section/data?type=${id}`
    );
    const respone = await data.json();
    return { data: respone, type: id.toLowerCase() };
  }
);

const getHeroSlice = createSlice({
  name: "heroReader",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(onFetchHero.pending, (state, action) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(onFetchHero.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state[payload.type] = payload.data;
        state.error = "";
      })
      .addCase(onFetchHero.rejected, (state, { payload, error }) => {
        state.isLoading = false;
        state[payload.type] = [];
        state.error = error.message;
      });
  },
});

export default getHeroSlice.reducer;
