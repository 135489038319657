import { Field, Form, Formik } from "formik";
import moment from "moment/moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";

const timeSchedule = [
  {
    time: "10.00 am",
  },
  {
    time: "10.30 am",
  },
  {
    time: "11.00 am",
  },
  {
    time: "11.30 am",
  },
  {
    time: "12.00 pm",
  },
  {
    time: "12.30 pm",
  },
  {
    time: "1.00 pm",
  },
];

const CallToActionModalData = ({ handleClose }) => {
  const [datePick, setDatePick] = useState();
  const [values, setValues] = useState({});
  const newSelectDate = moment(datePick).format();
  const [selectTime, setSelectTime] = useState("");
  const initialValues = {
    date: "",
    time: "",
    full_name: "",
    email: "",
    phone_number: "",
    message: "",
  };
  const handleTimeSelect = (data) => {
    setSelectTime(data);
  };
  const handleSubmit = (values) => {
    const newDate = {
      ...values,
      date: newSelectDate,
      time: selectTime,
    };
    if (newDate) {
      console.log(newDate);
      handleClose();
    }
  };

  return (
    <div>
      <Modal.Body>
        <div className="make-schedule-popup-form-wrap">
          <div className="make-schedule-popup-header">
            <h3>Schedule</h3>
          </div>
          <div className="make-schedule-popup-body">
            <ul className="make-schedule-popup-time">
              <li style={{ fontWeight: "700" }}>
                <i class="bx bx-time-five"></i> Select Time
              </li>
              {timeSchedule &&
                timeSchedule?.map((time, i) => (
                  <li
                    onClick={() => handleTimeSelect(time?.time)}
                    key={i}
                    className={selectTime === time?.time ? "active_class" : ""}
                  >
                    {time?.time}
                  </li>
                ))}
            </ul>
            <div className="make-schedule-popup-calendar">
              <div
                style={{
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  padding: " 6px 0",
                }}
              >
                <i class="bx bxs-calendar"></i> Select Date
              </div>
              <Calendar onChange={setDatePick} value={datePick} />
            </div>
            <div className="make-schedule-popup-form">
              <div
                style={{
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  padding: " 6px 0",
                  marginBottom: "10px",
                }}
              >
                <i class="bx bx-error-circle"></i> Your Information
              </div>
              <Formik
                initialValues={values || initialValues}
                // validationSchema={scheduleSchama}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="form-group mb-2">
                    <label htmlFor="full_name">First Name</label>
                    <Field
                      className="form-control"
                      id="full_name"
                      name="full_name"
                      placeholder="Full name"
                    />
                  </div>{" "}
                  <div className="form-group mb-2">
                    <label htmlFor="email_id">Email</label>
                    <Field
                      className="form-control"
                      id="email_id"
                      name="email"
                      type="email"
                      placeholder="Type your email"
                    />
                  </div>{" "}
                  <div className="form-group mb-2">
                    <label htmlFor="phone_number">Phone number</label>
                    <Field
                      className="form-control"
                      id="phone_number"
                      name="phone_number"
                      placeholder="type your phone number"
                    />
                  </div>{" "}
                  <div className="form-group mb-2">
                    <label htmlFor="message">Message</label>
                    <Field
                      className="form-control"
                      id="message"
                      name="message"
                      placeholder="Type your message"
                      as="textarea"
                    />
                  </div>
                  <div className="schedul-submit-button-area">
                    <button type="button" className="btn" onClick={handleClose}>
                      Cancel
                    </button>{" "}
                    <button type="submit" className="common-centro-btn">
                      Send
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default CallToActionModalData;
