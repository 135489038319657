import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import featureImg from "./feature.png";
const Feature = () => {
  return (
    <div>
      <div className="product-feature-main-area-wrap">
        <Container>
          <div className="product-feature-title">
            <p>Products Features</p>
            <h2>Make more with data</h2>
          </div>
          <div className="product-inner-area-wrap">
            <Row>
              <Col lg={3} md={6} className="my-auto">
                <div className="single-product-feature left">
                  <div className="product-feature-icon">
                    <i class="bx bxs-objects-horizontal-right"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Consumer analytics</h4>
                  </div>
                </div>
                <div className="single-product-feature left">
                  <div className="product-feature-icon">
                    <i>
                      <svg
                        version="1.1"
                        x="30px"
                        y="30px"
                        viewBox="0 0 1000 1000"
                        xmlSpace="preserve"
                        width="30px"
                        height="30px"
                      >
                        <g>
                          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                            <path d="M7912.2,4905.6c-586.4-63.4-1070.9-117.3-1074.7-121.1c-23-25,63.5-101.9,505.6-461.4l492.1-398l-94.2-107.7c-198-228.8-736.3-782.5-1049.7-1082.4C4857.3,981.8,3002.1-162.1,1116.1-702.3C970-744.6,827.7-796.5,800.8-815.8c-175-130.7-176.9-363.4-3.8-478.7c90.4-61.5,192.3-55.8,469.1,21.2C2385-956.1,3536.6-410.1,4663.2,339.7C5866.6,1141.4,7074,2198.7,8166,3408c96.1,105.7,134.6,138.4,153.8,123c13.5-9.6,232.6-186.5,486.4-390.3c253.8-203.8,472.9-380.7,486.4-390.3c15.4-11.5,30.8-13.5,42.3-1.9c15.4,15.4-196.1,2180.1-221.1,2253.2C9102.3,5034.5,9113.8,5034.5,7912.2,4905.6z" />
                            <path d="M7758.4,2012.3c-78.8-28.8-76.9,71.1-76.9-3391.3c0-3133.7,1.9-3322.1,34.6-3360.6c32.7-40.4,38.5-40.4,696-36.5l661.3,5.8l25,48.1c19.2,38.5,25,726.7,25,3350.9v3302.9l-48.1,46.1l-46.2,48.1l-621-1.9C8066,2023.8,7773.8,2018,7758.4,2012.3z" />
                            <path d="M5388-219.8c-30.8-32.7-32.7-219.2-32.7-2278.2c0-2197.4,0-2243.6,36.5-2262.8c25-11.5,274.9-19.2,680.6-19.2c597.9,0,644,1.9,672.9,34.6c30.8,32.7,32.7,217.2,32.7,2268.6v2232l-42.3,28.8c-36.5,26.9-134.6,30.8-680.5,30.8C5462.9-185.2,5416.8-187.1,5388-219.8z" />
                            <path d="M3040.6-1677.1c-26.9-26.9-30.8-192.3-30.8-1534.2c0-1434.2,1.9-1507.2,34.6-1536.1c32.7-28.8,107.7-32.7,674.8-32.7c403.7,0,651.7,7.7,676.7,19.2c36.5,19.2,36.5,57.7,36.5,1543.8c0,1161.2-5.8,1530.3-23.1,1547.6c-17.3,17.3-194.2,23.1-680.6,23.1C3155.9-1646.3,3065.5-1650.1,3040.6-1677.1z" />
                            <path d="M695.1-2746c-26.9-38.4-30.8-163.4-30.8-986.2c0-720.9,5.8-953.6,25-990.1l25-48.1l661.3-5.8c657.5-3.8,663.3-3.8,697.9,36.5c30.8,38.5,32.7,115.4,32.7,995.9c0,832.4-3.9,959.3-30.8,997.8l-28.8,42.3h-661.3H723.9L695.1-2746z" />
                          </g>
                        </g>
                      </svg>
                    </i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Intuitive dashboard</h4>
                  </div>
                </div>
                <div className="single-product-feature left">
                  <div className="product-feature-icon">
                    <i class="fa-solid fa-map-location-dot"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Smart suggestions</h4>
                  </div>
                </div>
              </Col>
              <Col lg={6} className={"mobile-display-none-feature-image"}>
                <div className="single-product-feature middle">
                  <img src={featureImg} alt="" />
                </div>
              </Col>
              <Col lg={3} md={6} className="my-auto">
                <div className="single-product-feature right">
                  <div className="product-feature-icon">
                    <i class="fa-solid fa-chart-pie"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Regional citations</h4>
                  </div>
                </div>
                <div className="single-product-feature right">
                  <div className="product-feature-icon">
                    <i class="fa-solid fa-truck-fast"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>market prognosis</h4>
                  </div>
                </div>
                <div className="single-product-feature right">
                  <div className="product-feature-icon">
                    <i class="fa-solid fa-clipboard-list"></i>
                  </div>
                  <div className="product-feature-content">
                    <h4>Responsive</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Feature;
