import React from "react";
import Hero from "../../Components/Hero/Hero";
import PricingTable from "../../Components/PricingTable/PricingTable";
import ProductAnalytics from "../../Components/ProductAnalytics/ProductAnalytics";
import ProductReview from "../../Components/ProductReview/ProductReview";
import MainHeader from "./../../common/header/MainHeader";
import AboutUs from "./../../Components/AboutUs/AboutUs";
import Blogs from "./../../Components/Blogs/Blogs";
import Feature from "./../../Components/Feature/Feature";
import MainFooter from "./../../Components/Footer/MainFooter";
import OurPretner from "./../../Components/OurPretner/OurPretner";
import WorksArea from "./../../Components/WorksArea/WorksArea";

const FieldX = () => {
  return (
    <div>
      <MainHeader />
      <Hero />
      <Feature />
      <AboutUs />
      <ProductAnalytics />
      <WorksArea />
      {/* <Faq /> */}
      <PricingTable />
      <OurPretner />
      <ProductReview />
      <Blogs />
      <MainFooter />
    </div>
  );
};

export default FieldX;
