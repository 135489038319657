import "animate.css/animate.min.css";
import React from "react";
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import { navbarRouter } from "./components/common/header/createNavbar/createBrowserRouter";
function App() {
  return (
    <div className="App">
      <RouterProvider router={navbarRouter}></RouterProvider>
    </div>
  );
}

export default App;
