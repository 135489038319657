import React, { useState } from "react";
import { Link } from "react-scroll";

import "../../assets/scss/header.scss";
import mainLogo from "../logo.svg";
import { useScrollHandler } from "./../useScrollHandler";
const MainHeader = () => {
  const [toggle, setToggle] = useState(false);
  const scroll = useScrollHandler();
  return (
    <header className={scroll ? "header-main-area active" : "header-main-area"}>
      <div className="container">
        <div className="header-inner-wrap">
          <div className="header-logo">
            <Link to="hero">
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          <div className="menuToggle" onClick={() => setToggle(!toggle)}>
            {toggle ? (
              <button className="navbar_mobile_close remove">
                <span></span>
                <span></span>
                <span></span>
              </button>
            ) : (
              <button className=" navbar_mobile_menu_bar">
                <span></span>
                <span></span>
                <span></span>
              </button>
            )}
          </div>
          <nav
            className={
              toggle ? "navbar_mobile_menu show" : "navbar_mobile_menu hide"
            }
          >
            <div className="header-navbar">
              <ul>
                <li>
                  <Link className="navbar__link" to="hero">
                    Features
                  </Link>
                </li>
                <li>
                  <Link className="navbar__link" to="about_us" offset={-100}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="navbar__link" to="works">
                    How It Works
                  </Link>
                </li>
                <li>
                  <Link className="navbar__link" to="pricing">
                    Pricing
                  </Link>
                </li>

                <li>
                  <Link className="navbar__link" to="clients">
                    Clients
                  </Link>
                </li>
                <li>
                  <Link className="navbar__link" to="Testimonials">
                    Blog
                  </Link>
                </li>
                <li>
                  <a
                    href="https://maacsolutions.com/course-demo-request?coursename=DDC"
                    target="_blank"
                    rel="noreferrer"
                    className="navbar__link header-login-btn"
                  >
                    Get Demo
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
