import React from "react";
import { Container } from "react-bootstrap";
import bgBottom from "./hero-bottom.png";
import fullBgMain from "./main-bg.png";
const Hero = () => {
  return (
    <div id="hero">
      <div className="hero-main-area-wrap">
        <img src={fullBgMain} alt="" />
        <Container>
          <div className="hero-inner-area-wrap">
            <div className="hero-top-area-content">
              <h2>
                Sell your products, at ease at <br />
                every door
              </h2>

              <div className="hero-top-bottom-button-area">
                <a
                  href="https://maacsolutions.com/course-demo-request?coursename=DDC"
                  target="_blank"
                  rel="noreferrer"
                >
                  Request for Demo
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.maacddc.ddc"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </div>
            </div>
            <div className="hero-bottom-area-image">
              <img src={bgBottom} alt="" />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
