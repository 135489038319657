import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-scroll";
import "./footer.scss";
import logo from "./logo (2) 1.png";
const MainFooter = () => {
  return (
    <div className="footer-main-area-wrap">
      <Container>
        <Row>
          <Col lg="4">
            <div className="footer-left-content-info">
              <img src={logo} alt="" />
              <p>
                We aim to be the preferred supplier in Asia for all of our
                clients globally.
              </p>
              <div className="social-media-list">
                <Link to="#">
                  <i className="bx bxl-facebook"></i>
                </Link>
                <Link to="#">
                  <i className="bx bxl-instagram"></i>
                </Link>
                <Link to="#">
                  <i className="bx bxl-linkedin"></i>
                </Link>
                <Link to="#">
                  <i className="bx bxl-twitter"></i>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg="8">
            <Row>
              <Col lg="4">
                <div className="footer-right-single-content">
                  <h5>Useful info</h5>
                  <ul>
                    <li>
                      <Link to="!#">Terms of use</Link>
                    </li>
                    <li>
                      <Link to="!#">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="!#">Media inquiries</Link>
                    </li>
                    <li>
                      <Link to="!#">Cookies settings</Link>
                    </li>
                    <li>
                      <Link to="!#">Terms of use</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="4">
                <div className="footer-right-single-content">
                  <h5>Need help</h5>
                  <ul>
                    <li>
                      <Link to="!#">Products</Link>
                    </li>
                    <li>
                      <Link to="!#">Work Process</Link>
                    </li>
                    <li>
                      <Link to="!#">Connect</Link>
                    </li>
                    <li>
                      <Link to="!#">Meet People</Link>
                    </li>
                    <li>
                      <Link to="!#">Work with us</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="4">
                <div className="footer-right-single-content">
                  <h5>Address</h5>
                  <div className="company-address-info-right">
                    <div className="single-company-info">
                      <h6>UNITED KINGDOM</h6>
                      <p>
                        4, Quadrum Park Industrial EstateOld Portsmouth Rd,
                        Guildford, GU3 1LU
                      </p>
                    </div>
                    <div className="single-company-info">
                      <h6>HONG KONG</h6>
                      <p>
                        16/F Citicorp Centre, N0.18 Whitfield Road Causeway Bay
                      </p>
                    </div>{" "}
                    <div className="single-company-info">
                      <h6>BANGLADESH HQ</h6>
                      <p>
                        House 7/7A, Sector 17Block H-1, BGMEA Complex Uttara,
                        Dhaka-1230
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainFooter;
