import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import Favicon from "react-favicon";
import { ReactTitle } from "react-meta-tags";
import { Link, useLocation } from "react-router-dom";
import favIcons from "../Home/favicon.ico";
import SubmitReq from "./icon/SubmitReq";
import pageImageBg from "./signature-story-bg.png";
const SignatureStoryMenu = () => {
  const navigate = useLocation();
  return (
    <div
      className="signature-story-nav-menu-page"
      style={{ backgroundImage: `url(${pageImageBg})` }}
    >
      <div className="signature-main-area">
        {navigate?.pathname === "/centro/signature-story" && (
          <>
            <ReactTitle title="Centro" />
            <Favicon url={favIcons} />
          </>
        )}
        <Container>
          <Row style={{ alignItems: "center" }}>
            <Col lg="4" className="first-menu-row">
              <ScrollAnimation animateIn="animate__fadeInDown" duration={0.5}>
                <div className="menu-small-row">
                  <Row className="mb-3" style={{ alignItems: "flex-end" }}>
                    <Col lg="3">
                      <div className="s-menu-item-empty small-box"></div>
                    </Col>
                    <Col lg="9">
                      <div className="s-menu-item-empty empty-menu-height"></div>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInDown" duration={1.5}>
                <div className="menu-full-row">
                  <Row className="mb-3">
                    <Col lg="3">
                      <div className="s-menu-item-empty small-box"></div>
                    </Col>
                    <Col lg="9">
                      <div className="s-menu-item-link">
                        <Link to="/centro/submit-requirments">
                          <div className="menu-item-box">
                            <div className="s-icon">
                              <SubmitReq />
                            </div>
                            <h5>
                              Submit your <br /> requirements
                            </h5>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInDown" duration={1.9}>
                <div className="menu-small-row">
                  <Row className="mb-3">
                    <Col lg="3">
                      <div className="s-menu-item-empty small-box"></div>
                    </Col>
                    <Col lg="9">
                      <div className="s-menu-item-empty empty-menu-height"></div>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
              {/* <ScrollAnimation
              animateIn="animate__fadeInDown"
              duration={1}
            ></ScrollAnimation> */}
            </Col>

            <Col lg="4">
              <div className="menu-full-middle-row">
                <Row className="mb-3">
                  <ScrollAnimation
                    animateIn="animate__fadeInDown"
                    duration={0.5}
                  >
                    <Col lg="12" className="mb-3">
                      <div className="s-menu-item-link">
                        <Link to="/centro/our-augmented">
                          <div className="menu-item-box">
                            <div className="s-icon">
                              <SubmitReq />
                            </div>
                            <h5>Our augmented store</h5>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInDown"
                    duration={1.5}
                  >
                    <Col lg="12" className="mb-3">
                      <div className="s-menu-item-link main-middle">
                        <div className="menu-item-box">
                          <h3>Our Competitiveness accumulated</h3>
                        </div>
                      </div>
                    </Col>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInDown"
                    duration={1.9}
                  >
                    <Col lg="12">
                      <div className="s-menu-item-empty back-to-bome-button">
                        <Link to="/centro">
                          <i className="bx bx-left-arrow-alt"></i>
                          <span>Back To Home</span>
                        </Link>
                      </div>
                    </Col>
                  </ScrollAnimation>
                </Row>
              </div>
            </Col>
            <Col lg="4" className="last-menu-row">
              <ScrollAnimation animateIn="animate__fadeInDown" duration={0.5}>
                <div className="menu-small-row">
                  <Row className="mb-3" style={{ alignItems: "flex-end" }}>
                    <Col lg="9">
                      <div className="s-menu-item-empty empty-menu-height"></div>
                    </Col>
                    <Col lg="3">
                      <div className="s-menu-item-empty small-box"></div>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInDown" duration={1.5}>
                <div className="menu-full-row">
                  <Row className="mb-3">
                    <Col lg="9">
                      <div className="s-menu-item-link">
                        <Link to="/centro/brand-work">
                          <div className="menu-item-box">
                            <div className="s-icon">
                              <SubmitReq />
                            </div>
                            <h5>Brands we work with</h5>
                          </div>
                        </Link>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="s-menu-item-empty small-box"></div>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInDown" duration={1.9}>
                <div className="menu-small-row">
                  <Row className="mb-3">
                    <Col lg="9">
                      <div className="s-menu-item-link">
                        {" "}
                        <Link to="/centro/our-factory-link">
                          <div className="menu-item-box">
                            <div className="s-icon">
                              <SubmitReq />
                            </div>
                            <h5>Our factory link up</h5>
                          </div>{" "}
                        </Link>
                      </div>
                    </Col>

                    <Col lg="3">
                      <div className="s-menu-item-empty small-box"></div>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SignatureStoryMenu;
