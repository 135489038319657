import imageOne from "./img/1.png";
import imageTwo from "./img/2.png";
import imageThree from "./img/3.png";
import imageFour from "./img/4.png";
import imageFive from "./img/5.png";
import imageSix from "./img/6.png";
export const productAnalyticsData = [
  {
    id: 1,
    title: "Consumer analytics",

    img: imageOne,
  },
  {
    id: 2,
    title: `Intuitive dashboard`,

    img: imageTwo,
  },
  {
    id: 3,
    title: `Smart suggestions
    `,

    img: imageThree,
  },
  {
    id: 4,
    title: `Regional citations`,

    img: imageFour,
  },
  {
    id: 5,
    title: `market prognosis`,

    img: imageFive,
  },
  {
    id: 6,
    title: "Responsive",

    img: imageSix,
  },
];
