import React from "react";

const GalleryExport = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 9.99988C10.1046 9.99988 11 9.10445 11 7.99988C11 6.89531 10.1046 5.99988 9 5.99988C7.89543 5.99988 7 6.89531 7 7.99988C7 9.10445 7.89543 9.99988 9 9.99988Z"
        stroke="#74788D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 1.99988H9C4 1.99988 2 3.99988 2 8.99988V14.9999C2 19.9999 4 21.9999 9 21.9999H15C20 21.9999 22 19.9999 22 14.9999V9.99988"
        stroke="#74788D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 7.99988V1.99988L20 3.99988"
        stroke="#74788D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 1.99988L16 3.99988"
        stroke="#74788D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66992 18.9499L7.59992 15.6399C8.38992 15.1099 9.52992 15.1699 10.2399 15.7799L10.5699 16.0699C11.3499 16.7399 12.6099 16.7399 13.3899 16.0699L17.5499 12.4999C18.3299 11.8299 19.5899 11.8299 20.3699 12.4999L21.9999 13.8999"
        stroke="#74788D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GalleryExport;
