import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "react-bootstrap";
import LongArrow from "../../assets/icons/LongArrow";

const WorkWithUs = () => {
  return (
    <div className="work-with-us-main-area" id="work-with-us">
      <Container>
        <div className="work-with-us-content-area">
          <div className="work-with-us-title">
            <ScrollAnimation animateIn="animate__fadeInRight">
              {" "}
              <h4>Work with us</h4>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInLeft">
              {" "}
              <p>Join Our Team and Let's Make Magic Together.</p>
            </ScrollAnimation>
          </div>
          <div className="work-with-us-post-list">
            <div className="single-work-list">
              <div className="single-work-list-left">
                <ScrollAnimation animateIn="animate__fadeInRight">
                  {" "}
                  <h5>Merchandising Executive</h5>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <p>Deadline: 5 May 2023</p>
                </ScrollAnimation>
              </div>
              <div className="single-work-list-right-btn">
                <ScrollAnimation animateIn="animate__fadeInRight">
                  {" "}
                  <button className="common-centro-btn">
                    Apply Now <LongArrow />
                  </button>
                </ScrollAnimation>
              </div>
            </div>{" "}
            <div className="single-work-list">
              <div className="single-work-list-left">
                <ScrollAnimation animateIn="animate__fadeInRight">
                  {" "}
                  <h5>Quality Assurance Engineer</h5>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <p>Deadline: 5 May 2023</p>
                </ScrollAnimation>
              </div>
              <div className="single-work-list-right-btn">
                <ScrollAnimation animateIn="animate__fadeInRight">
                  {" "}
                  <button className="common-centro-btn">
                    Apply Now <LongArrow />
                  </button>
                </ScrollAnimation>
              </div>
            </div>{" "}
            <div className="single-work-list">
              <div className="single-work-list-left">
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <h5>Business Development Executive</h5>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <p>Deadline: 5 May 2023</p>
                </ScrollAnimation>
              </div>
              <div className="single-work-list-right-btn">
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <button className="common-centro-btn">
                    Apply Now <LongArrow />
                  </button>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WorkWithUs;
