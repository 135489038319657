import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import Favicon from "react-favicon";
import { ReactTitle } from "react-meta-tags";
import { useLocation } from "react-router-dom";
import MessagesIcon from "../../assets/icons/MessagesIcon";
import ChatBoxShow from "../../components/common/ChatBoxShow";
import MainFooter from "../../components/common/footer/MainFooter";
import MainHeader from "../../components/common/header/MainHeader";
import AboutUs from "../../components/home/AboutUs";
import CallToAction from "../../components/home/CallToAction";
import CentroFeature from "../../components/home/CentroFeature";
import Hero from "../../components/home/Hero";
import MeetOurPeople from "../../components/home/MeetOurPeople";
import OurProduct from "../../components/home/OurProduct";
import SignatureStory from "../../components/home/SignatureStory";
import WorkWithUs from "../../components/home/WorkWithUs";
import WorkingProcessNew from "../../components/home/WorkingProcessNew";
import logo from "./centro-image.png";
import favIcons from "./favicon.ico";
import "./home.scss";
const Home = () => {
  const navigate = useLocation();
  const [openChat, setOpenChat] = useState(false);
  return (
    <div
      className="centro-website"
      style={{
        cursor: ` url(${logo}), auto`,
      }}
    >
      <div className="centro-chat-area">
        {openChat && (
          <div className="centro-chat-box">
            <ChatBoxShow />
          </div>
        )}
        <div className="centro-chat-btn" onClick={() => setOpenChat(!openChat)}>
          {openChat ? (
            <button className="chat-close-btn">
              <span></span>
              <span></span>
            </button>
          ) : (
            <button>
              Chat With Us! <MessagesIcon />
            </button>
          )}
        </div>
      </div>
      {navigate?.pathname === "/centro" && (
        <>
          <ReactTitle title="Centro" />
          <Favicon url={favIcons} />
        </>
      )}
      <MainHeader />
      <Hero />
      <OurProduct />
      <AboutUs />
      <WorkingProcessNew />
      <SignatureStory />
      <MeetOurPeople />
      <CentroFeature />
      <WorkWithUs />
      <CallToAction />
      <MainFooter />
    </div>
  );
};

export default Home;
