import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import Favicon from "react-favicon";
import { ReactTitle } from "react-meta-tags";
import { useLocation } from "react-router-dom";
import LongArrow from "../../../assets/icons/LongArrow";
import favIcons from "../../Home/favicon.ico";
import "../preloader.scss";
import {
  requirementList,
  selectProducts,
} from "../signatureStoryData/requiement/requirementList";
import bgModalImage from "./images/modal-bg.png";
const SubmitYourRequirments = () => {
  const navigate = useLocation();
  const [selectMenu, setSelectMenu] = useState("product");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [selectedProduct, setSelecteProduct] = useState([]);
  // const [selectedProduct2, setSelecteProduct2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    let timeTravel;
    if (show) {
      timeTravel = setTimeout(() => setLoading(false), 5000);
    }
    return () => clearInterval(timeTravel);
  }, [show]);
  console.log(selectedProduct?.type?.includes());

  const handleSelectedProduct = (data) => {
    setSelecteProduct((prev) => [...prev, data]);

    if (data?.type?.includes("product")) {
      setSelectMenu("design");
    } else if (data?.type?.includes("design")) {
      setSelectMenu("fabrics");
    } else if (data?.type?.includes("fabrics")) {
      setSelectMenu("print");
    } else if (data?.type?.includes("print")) {
      setSelectMenu("accessories");
    } else if (data?.type?.includes("accessories")) {
      setSelectMenu("location");
    } else {
      setSelectMenu("product");
    }
  };

  return (
    <div className="submit-your-requirements-main-wrap">
      {navigate?.pathname === "/centro/submit-requirments" && (
        <>
          <ReactTitle title="Centro" />
          <Favicon url={favIcons} />
        </>
      )}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body>
          <Container>
            <div className="modal-loading-body-with-content">
              {loading ? (
                <div className="popup-modal-loading-bg">
                  <div className="loading-bg">
                    <img src={bgModalImage} alt="" />
                  </div>
                  <div className="edge-calculator-loader-area">
                    <div>
                      <span class="loader"></span>
                      <h4>
                        Calculating from product <br /> requirement
                      </h4>
                    </div>
                  </div>
                </div>
              ) : (
                <Container>
                  <button
                    className="modal-close-button-after-submit"
                    onClick={handleClose}
                  >
                    <i className="bx bx-x-circle"></i>
                  </button>
                  <Row className="g-0 mb-5">
                    <Col lg="5">
                      <div className="total-savings-info">
                        <h1>13%</h1>
                        <h6>Savings*</h6>
                      </div>
                    </Col>
                    <Col lg="7" className="my-auto">
                      <div className="total-saving-content-right">
                        <h3>
                          From Average <br /> market rates
                        </h3>
                      </div>
                    </Col>
                  </Row>

                  <div className="row g-0">
                    <Col lg="5">
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          color: "rgba(1, 1, 1, 0.5)",
                        }}
                        className="popup-text-infor-bottom"
                      >
                        <p>*Based on your selection</p>
                      </div>
                    </Col>
                    <Col lg="7">
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          className="common-centro-btn "
                          onClick={handleClose}
                        >
                          Submit & Connect
                        </button>
                      </div>
                    </Col>
                  </div>
                </Container>
              )}
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Container>
        <div className="requirements-header">
          <h2>Submit your requirements</h2>
        </div>
        <Row>
          <Col lg="4">
            <div className="submit-your-requiments-list-wrap">
              <ul>
                {requirementList?.map((list) => (
                  <li
                    onClick={() => setSelectMenu(list?.value)}
                    className={list?.value === selectMenu ? "active" : ""}
                  >
                    {list?.name} <i className="bx bx-chevron-right"></i>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg="8">
            <div className="location-selection-content-area">
              <>
                {selectMenu === "location" ? (
                  <div className="location-selection">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Delivery Location</Form.Label>
                      <Form.Select aria-label="Select Location">
                        <option value="bangladesh">Bangladesh</option>
                        <option value="usa">USA</option>
                        <option value="uk">UK</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                ) : (
                  <Row className="g-0">
                    {selectProducts
                      ?.filter((el) => el?.type?.includes(selectMenu))
                      ?.map((product, idx) => (
                        <ScrollAnimation
                          animateIn="animate__fadeInDown"
                          duration={1}
                          className="col-lg-6 card-border-submit"
                        >
                          <div
                            className="requirements-card "
                            onClick={() => handleSelectedProduct(product)}
                          >
                            <img src={product?.image} alt="" />
                            <h6>{product?.name}</h6>
                          </div>
                        </ScrollAnimation>
                      ))}
                  </Row>
                )}
              </>
            </div>
          </Col>
        </Row>
        <div className="requirements-footer">
          {selectMenu === "location" ? (
            <button className="common-centro-btn" onClick={handleShow}>
              <span> Edge Calculator</span> <LongArrow />
            </button>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default SubmitYourRequirments;
