import React, { useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import imageOne from "./img/1.png";
import { productAnalyticsData } from "./productAnalyticsData";
const ProductAnalytics = () => {
  const [imageLink, setImageLink] = useState(imageOne);
  const handleImageLink = (imageLink) => {
    setImageLink(imageLink);
  };
  console.log(imageLink);
  return (
    <div>
      <div className="product-analytics-main-area-wrap">
        <Container>
          <div className="product-analytics-title">
            <p>Products Features</p>
            <h2>
              Make your product better <br /> than the competition
            </h2>
          </div>
          <Row>
            <Col lg={6} className="my-auto">
              <div className="product-analytics-accordion-left">
                <Accordion defaultActiveKey="1">
                  {productAnalyticsData.map((item, index) => (
                    <Accordion.Item eventKey={item.id} key={index}>
                      <Accordion.Header
                        onClick={() => handleImageLink(item?.img)}
                      >
                        {item.title}
                      </Accordion.Header>
                      <Accordion.Body className="mobile-show-desktop-hide">
                        <img src={imageLink} alt="" />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Col>
            <Col lg={6}>
              <div className="product-analytics-right-area">
                {imageLink ? (
                  <img src={imageLink} alt="" />
                ) : (
                  <img src={imageLink} alt="" />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ProductAnalytics;
