import image1 from "./1.png";
import image2 from "./2.png";
import image3 from "./3.png";
import image4 from "./4.png";
import image5 from "./5.png";

export const brandData = [
  {
    name: "POSH GARMENTS",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image1,
    buttonLink: "/centro/our-factory-link",
  },
  {
    name: "POSH GARMENTS",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image2,
    buttonLink: "/centro/our-factory-link",
  },
  {
    name: "POSH GARMENTS 2",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image3,
    buttonLink: "/centro/our-factory-link",
  },
  {
    name: "POSH GARMENTS 3",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image4,
    buttonLink: "/centro/our-factory-link",
  },
  {
    name: "POSH GARMENTS 4",
    dec: "The marker planner uses full-size patterns and arrange",
    image: image5,
    buttonLink: "/centro/our-factory-link",
  },
];
