import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  category: [],
  error: "",
};

export const onFetchCategory = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const products = await fetch(
      "https://maacsolutionsbackend-api.salesx-staging.xyz/api/v1/category/data?pageNo=0&limit=0"
    );
    return products.json();
  }
);

const getCategorySlice = createSlice({
  name: "productReader",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(onFetchCategory.pending, (state, action) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(onFetchCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.category = action.payload;
        state.error = "";
      })
      .addCase(onFetchCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.category = [];
        state.error = action.error.message;
      });
  },
});

export default getCategorySlice.reducer;
