import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LongArrow from "../../assets/icons/LongArrow";
import { onFetchCategory } from "../../store/product/categorySlice";
import { onFetchProduct } from "../../store/product/productSlice";
import productImage from "./img/product.png";
const OurProduct = () => {
  const { isLoading, products } = useSelector((state) => state?.product);
  const { categories } = useSelector((state) => state?.category?.category);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onFetchProduct());
  }, [dispatch]);
  useEffect(() => {
    dispatch(onFetchCategory());
  }, [dispatch]);
  const [filter, setFilter] = useState("");
  const { products: productList } = products;
  const productFilter = (item) => {
    let result = item;
    if (filter) {
      result = result?.filter(
        (el) => el?.categoryId?.name?.toLowerCase() === filter?.toLowerCase()
      );
    }
    return result;
  };
  const filterCategory = categories?.filter((category) =>
    productList?.some((product) => product?.categoryId?.name === category?.name)
  );
  return (
    <div className="our-product-main-area-wrap" id="our-product">
      <Container>
        <div className="our-product-content-area-wrap">
          <div className="our-product-title">
            <h3>Our Products</h3>
          </div>
          <div className="our-product-list-area">
            <div className="our-product-filter-navbar">
              <ul>
                <li onClick={() => setFilter("")}>All</li>
                {filterCategory?.map((data, i) => (
                  <li key={i} onClick={() => setFilter(data?.name)}>
                    {data?.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="our-product-filter-view-list">
              <ScrollAnimation animateIn="animate__fadeInDown" duration={1}>
                <Row>
                  {isLoading ? (
                    <div className="product-loading-area">
                      <h2>Loading...</h2>
                    </div>
                  ) : (
                    <>
                      {productFilter(productList)
                        ?.slice(0, 3)
                        ?.map((product, idx) => (
                          <Col lg="4" key={idx}>
                            <div className="product-single-card-wrap">
                              <div className="product-card-image-part">
                                {product?.image === "" ? (
                                  <img src={productImage} alt="" />
                                ) : (
                                  <img src={product?.image} alt="" />
                                )}
                              </div>
                              <div className="product-card-hover-part">
                                <div className="p-c-h-inner-content">
                                  <p>{product?.name}</p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </>
                  )}
                </Row>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className="our-product-button-bottom">
          <button className="common-centro-btn">
            View More <LongArrow />
          </button>
        </div>
      </Container>
    </div>
  );
};

export default OurProduct;
